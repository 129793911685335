import React from "react";

import styled from "styled-components";

import { Theme } from "config/theme";
import { ICustomerData } from "types";

import { DecisionHierarchySection } from "./credit-summary-sections/decision-hierarchy";
import { HistorySection } from "./credit-summary-sections/history-section";
import { SummarySection } from "./credit-summary-sections/summary-section";

export interface ICreditSummaryProps {
    className?: string;
    customerData: ICustomerData;
}

export const CreditSummary: React.FC<ICreditSummaryProps> = ({ className, customerData }) => (
    <Wrapper className={className} data-testid={`credit-summary-${customerData.ID}`}>
        <SummarySection customerData={customerData} />
        <DecisionHierarchySection customerData={customerData} />
        <HistorySection customerData={customerData} />
    </Wrapper>
);

const Wrapper = styled.div`
    width: 600px;
`;

export const Heading = styled.div`
    font-weight: 600;
    font-size: 14px;
    padding: 10px 0 10px 16px;
    color: ${Theme.neutral600};
    background-color: ${Theme.neutral100};
`;

export const DataContainer = styled.div`
    padding: 16px 16px 0;
    background-color: ${Theme.neutral50};

    :last-child {
        padding-bottom: 16px;
    }
`;

export const DataHeading = styled.p`
    font-size: 14px;
    font-weight: 600;
    color: ${Theme.neutral400};
    margin-bottom: 8px;

    strong {
        color: ${Theme.neutral600};
    }
`;

export const DataInfo = styled.span`
    font-size: 14px;
    color: ${Theme.neutral600};
`;

export const Divider = styled.div`
    margin-top: 16px;
    width: 568px;
    height: 1px;
    background-color: ${Theme.neutral100};
`;

export const Paragraph = styled.p`
    line-height: 1.6;
`;
