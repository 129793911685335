import React from "react";

import { SystemNotification, SystemNotificationProvider } from "@wayhome-uk/components";
import { GlobalStyles as DSGlobalStyles, Theme as DSTheme } from "@wayhome-uk/design-system";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { GlobalStyles as PeanutGlobalStyles } from "components/global-styles";
import { Theme as PeanutTheme } from "config/theme";
import { CreditCheckReview, HomePage, LoginPage } from "pages";

// These providers and global styles are set here because the rest of Credit Check Review does not use
// the design system yet. When/if it does, these can be moved to app.tsx
export const Routes = () => (
    <Switch>
        <Route path="/" exact={true}>
            <HomePage />
        </Route>
        <Route path="/login">
            <ThemeProvider theme={DSTheme}>
                <DSGlobalStyles />
                <LoginPage />
            </ThemeProvider>
        </Route>
        <Route path="/credit-check-review/:submittedBy">
            <ThemeProvider theme={{ ...DSTheme, ...PeanutTheme }}>
                <PeanutGlobalStyles />
                <SystemNotificationProvider>
                    <CreditCheckReview />
                    <SystemNotification />
                </SystemNotificationProvider>
            </ThemeProvider>
        </Route>
    </Switch>
);
