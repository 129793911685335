export const Theme = {
    fontFamily: "Helvetica, sans-serif",

    // Colors
    coral300: "#fcbdb5",
    coral400: "#f49689",
    coral500: "#ff7f6e",
    coral600: "#ee6f5e",
    coral700: "#e96250",
    grey200: "#f8f7f6",
    grey300: "#f2efed",
    grey400: "#ded7d3",
    grey500: "#b7b7b7",
    grey600: "#8d9393",
    grey700: "#5b6565",
    grey700RGB: "65, 75, 75",
    failure: "#ff5267",
    success: "#2ccda9",

    // Comparison colours
    matched: "#00D764",
    similar: "#FADE4B",
    misMatched: "#EB0000",
    unknown: "#B9B7B1",

    // multi choice buttons
    multiChoiceBorderWidth: 1,
    multiChoiceBorderRadius: 4,

    // radius
    radius1: 4,
    radius2: 16,

    // new colours - borrowed from design system
    primary50: "#DEEAEB",
    primary500: "#025A65",

    action75: "#FBEED5",
    action500: "#EBB242",
    action800: "#6C521E",
    action900: "#423212",

    neutral0: "#FFFFFF",
    neutral50: "#f5f4f0",
    neutral100: "#EAE8E0",
    neutral200: "#D5D3CC",
    neutral400: "#9D9B96",
    neutral600: "#6C6B67",
    neutral700: "#504F4C",
    neutral800: "#333331",

    green100: "#92CCAE",
    green500: "#2A7556",
    green800: "#264A39",

    red100: "#F5C2BA",
    red500: "#DE7573",
    red800: "#913946",

    yellow100: "#F9E394",
    yellow500: "#EBB242",
    yellow800: "#AB8627",
};
