import React, { ReactNode } from "react";

import styled from "styled-components";

import LoadingIcon from "components/media/svg/loading-icon.svg";

export interface Props {
    className?: string;
    children?: ReactNode;
}

export const LoadingIndicator: React.FC<Props> = ({ className, children }) => (
    <Wrapper data-testid="loading-indicator" className={className}>
        {children}
    </Wrapper>
);

const Wrapper = styled.div`
    font-size: 48px;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    background: url(${LoadingIcon}) 100% 100% no-repeat;
`;
