export const VerticalMultiChoiceData = {
    decision: [
        {
            displayName: "Pass",
            name: "decision",
            value: "Pass",
        },
        {
            displayName: "Fail",
            name: "decision",
            value: "Fail",
        },
        {
            displayName: "Flag",
            name: "decision",
            value: "Flag",
        },
    ],
};
