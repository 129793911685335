import React from "react";

import { format } from "date-fns";
import { Formik } from "formik";
import styled from "styled-components";

import { Button } from "components/buttons";
import { creditContainerValidationSchema } from "components/credit-container/credit-container-validation-schema";
import { ErrorMessage } from "components/error-message";
import { VerticalMultiChoiceButton } from "components/vertical-multi-choice-button";
import { VerticalMultiChoiceData } from "components/vertical-multi-choice-button/__mocks__/vertical-multi-choice-data";
import { Theme } from "config/theme";

export interface Props {
    className?: string;
    date: string;
    ID: string;
    decision: string;
    comments: string;
    onCancel: () => void;
    onSubmit: (ID: string, values: { decision: string; comments: string }) => void;
}

export const CreditContainer: React.FC<Props> = ({ className, date, decision, comments, ID, onCancel, onSubmit }) => {
    const handleSubmit = (values: { decision: string; comments: string }) => {
        onSubmit(ID, values);
    };

    return (
        <Wrapper data-testid={`credit-container-${ID}`} className={className}>
            <Formik
                initialValues={{ decision: decision, comments: comments }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
                validationSchema={creditContainerValidationSchema}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <TopBar>
                            <Header>Credit report: {date && format(new Date(date), "do MMM yyyy")}</Header>
                            <ButtonWrapper>
                                <CancelButton onClick={onCancel}>Cancel</CancelButton>
                                <SaveButton type="submit">Save assessment</SaveButton>
                            </ButtonWrapper>
                        </TopBar>
                        {props.errors.decision && <StyledErrorMessage>{props.errors.decision}</StyledErrorMessage>}
                        <BottomContainer>
                            <DecisionBox>
                                <BoxHeader>Decision</BoxHeader>
                                <VerticalMultiChoiceButton
                                    choices={VerticalMultiChoiceData.decision}
                                    handleChange={props.handleChange}
                                    selectedValue={props.values.decision}
                                />
                            </DecisionBox>
                            <CommentsBox>
                                <BoxHeader>
                                    Comments <OptionalText>optional</OptionalText>
                                </BoxHeader>
                                <Comments
                                    as="textarea"
                                    onChange={props.handleChange}
                                    name="comments"
                                    value={props.values.comments}
                                />
                            </CommentsBox>
                        </BottomContainer>
                    </form>
                )}
            </Formik>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 600px;
    background-color: gray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const TopBar = styled.div`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 50px;
    background-color: ${Theme.neutral700};
    display: flex;
    justify-content: space-between;
`;

const Header = styled.span`
    padding: 16px;
    color: ${Theme.neutral0};
`;

const ButtonWrapper = styled.div`
    padding: 10px;
`;

const StyledButton = styled(Button)`
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    border-radius: 4px;
`;

const SaveButton = styled(StyledButton)`
    background-color: ${Theme.action500};
    color: ${Theme.action900};
`;

const CancelButton = styled(StyledButton)`
    margin-right: 16px;
    background-color: ${Theme.neutral200};
    color: ${Theme.neutral800};
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin: 16px 16px 0;
`;

const BottomContainer = styled.div`
    display: flex;
`;

const DecisionBox = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
`;

const CommentsBox = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
`;

const OptionalText = styled.span`
    font-size: 12px;
    font-weight: 400;
`;

const BoxHeader = styled.span`
    font-size: 14px;
    line-height: 100%;
    font-weight: 600;
    color: ${Theme.neutral0};
    margin-bottom: 8px;
`;

const Comments = styled.textarea`
    color: ${Theme.neutral600};
    padding: 16px;
    font-size: 14px;
    line-height: 1.2;
    width: 440px;
    height: 120px;
    border: none;
    border-radius: 4px;
`;
