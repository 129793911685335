export const decisionHierarchyData = [
    { displayName: "Comments", fieldName: "comments" },
    { displayName: "Bankruptcy or IVA", fieldName: "bankruptcy_or_iva" },
    { displayName: "CCJs", fieldName: "ccjs" },
    { displayName: "CII", fieldName: "cii" },
    { displayName: "Credit date", fieldName: "credit_date" },
    { displayName: "Decision", fieldName: "decision" },
    { displayName: "Default payments", fieldName: "default_payments" },
    { displayName: "Missed payments", fieldName: "missed_payments" },
    { displayName: "Mortgage", fieldName: "mortgage" },
    { displayName: "Number of CCJs", fieldName: "no_of_ccjs" },
    { displayName: "Number of outstanding CCJs", fieldName: "no_of_outstanding_ccjs" },
    { displayName: "Payday loans", fieldName: "pay_day_loans" },
    { displayName: "Risk", fieldName: "risk" },
    { displayName: "Submitted date", fieldName: "submitted_timestamp", formatType: "date" },
    {
        displayName: "Total default payments in the previous 12 months",
        fieldName: "total_default_payments_previous_12_months",
    },
    {
        displayName: "Total default payments in the previous 60 months",
        fieldName: "total_default_payments_previous_60_months",
    },
    {
        displayName: "Total missed payments in the previous 12 months",
        fieldName: "total_missed_payments_previous_12_months",
    },
    {
        displayName: "Total missed payments in the previous 60 months",
        fieldName: "total_missed_payments_previous_60_months",
    },
    { displayName: "Unsecured debt GHI ratio", fieldName: "unsecured_debt_ghi_ratio" },
    { displayName: "Unsecured debt servicing NHI", fieldName: "unsecured_debt_servicing_nhi" },
];
