import React from "react";

import { useAuth } from "react-oidc-context";
import { Redirect } from "react-router-dom";

export const HomePage: React.FC = () => {
    const { isAuthenticated, isLoading } = useAuth();

    if (!isLoading && !isAuthenticated) {
        return <Redirect to="/login" />;
    }

    return (
        <>
            <div>Add applicant/UUID in the URL to view the credit status!</div>
            <pre>/credit-check-review/:uuid</pre>
        </>
    );
};
