import React, { ReactNode } from "react";

import styled from "styled-components";

import AlertTriangle from "components/media/svg/alert-triangle.svg";
import { Theme } from "config/theme";

export interface Props {
    className?: string;
    children?: ReactNode;
}

export const ErrorMessage: React.FC<Props> = ({ className, children }) => (
    <Wrapper data-testid="error-message" className={className}>
        <ErrorText>{children}</ErrorText>
    </Wrapper>
);

const iconSize = 18;

const Wrapper = styled.div`
    padding: 12px 0 12px 16px;
    border: 1px solid ${Theme.misMatched};
    border-radius: 4px;
    background-color: ${Theme.neutral0};
`;

const ErrorText = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: ${Theme.misMatched};
    position: relative;
    padding-left: ${iconSize + 12}px;

    &::before {
        content: " ";
        background: url(${AlertTriangle}) 100% 100% no-repeat;
        position: absolute;
        width: ${iconSize}px;
        height: ${iconSize}px;
        left: 0;
    }
`;
