import { User } from "oidc-client-ts";

interface IUserAuth extends User {
    token_type: "Bearer" | "Token";
}

export const userAuthStorageKey = `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;

export const getAuthData = () => {
    try {
        const dataString = localStorage.getItem(userAuthStorageKey) ?? "{}";

        const useAuthData: IUserAuth = JSON.parse(dataString);

        return useAuthData;
    } catch {
        return {} as IUserAuth;
    }
};

export const customFetch = async (path: string, method: "GET" | "POST" | "PATCH", data?: any) => {
    try {
        const { access_token } = getAuthData();

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
        };
        const bodyData = data ? { body: JSON.stringify(data) } : {};

        const response = await fetch(path, {
            headers,
            method,
            ...bodyData,
        });

        return { ok: response.ok, status: response.status, body: await response.json() };
    } catch (caughtError) {
        return { ok: false, status: 0, body: caughtError, };
    }
};
