import React, { ChangeEvent, Fragment } from "react";

import styled, { css } from "styled-components/macro";

import { IconChecked } from "components/icons/icon-checked";
import { Choice, MultiChoiceButton } from "components/multi-choice-button";

export interface Props {
    className?: string;
    choices: Choice[];
    selectedValue?: any;
    handleChange: (event: ChangeEvent) => void;
}

export const VerticalMultiChoiceButton: React.FC<Props> = ({ className, choices, selectedValue, handleChange }) => {
    return (
        <Wrapper data-test-id="vertical-multi-choice" className={className}>
            {choices.map((choice, index) => {
                const isSelected =
                    selectedValue === undefined ? false : choice.value.toString() === selectedValue.toString();
                return (
                    <Fragment key={index}>
                        <StyledMultiChoiceButton
                            radioButtonName={choice.name}
                            radioButtonValue={choice.value}
                            onChange={handleChange}
                            isSelected={isSelected}
                            backgroundColor={isSelected ? choice.value.toString() : ""}
                        >
                            <ButtonText isSelected={isSelected} iconColor={choice.value.toString()}>
                                <SelectedWrapper>
                                    {isSelected && <StyledIconChecked iconColor={choice.value.toString()} />}
                                    {choice.displayName}
                                </SelectedWrapper>
                            </ButtonText>
                        </StyledMultiChoiceButton>
                    </Fragment>
                );
            })}
        </Wrapper>
    );
};

const StyledMultiChoiceButton = styled(MultiChoiceButton)<{ backgroundColor: string }>`
    display: inline-block;
    text-align: left;
    padding: 12px;

    border-color: ${(props) => props.theme.neutral100};

    ${({ backgroundColor }) => {
        switch (backgroundColor.toLowerCase()) {
            case "pass":
                return css`
                    background-color: ${(props) => props.theme.green500};
                    border: 1px solid ${(props) => props.theme.green500};
                `;
            case "fail":
                return css`
                    background-color: ${(props) => props.theme.red500};
                    border: 1px solid ${(props) => props.theme.red500};
                `;
            case "flag":
                return css`
                    background-color: ${(props) => props.theme.yellow500};
                    border: 1px solid ${(props) => props.theme.yellow500};
                `;
            default:
                return css`
                    background-color: ${(props) => props.theme.neutral0};
                    border: 1px solid ${(props) => props.theme.neutral200};
                `;
        }
    }};

    :first-child,
    :first-child::before {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    :not(:last-child),
    :not(:last-child)::before {
        border-bottom: none;
    }

    :last-child,
    :last-child::after {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
`;

const checkBoxSize = 16;

const SelectedWrapper = styled.div`
    display: flex;
`;

const checkedIconPositionCSS = css`
    top: 50%;
    margin-top: -${checkBoxSize / 2}px;
    left: 0;
`;

const StyledIconChecked = styled(IconChecked)<{ iconColor: string }>`
    position: absolute;
    ${checkedIconPositionCSS};
    ${({ iconColor }) => {
        switch (iconColor.toLowerCase()) {
            case "pass":
                return css`
                    stroke: ${(props) => props.theme.green800};
                    fill: ${(props) => props.theme.green800};
                `;
            case "fail":
                return css`
                    stroke: ${(props) => props.theme.red800};
                    fill: ${(props) => props.theme.red800};
                `;
            case "flag":
                return css`
                    stroke: ${(props) => props.theme.yellow800};
                    fill: ${(props) => props.theme.yellow800};
                `;
            default:
                return css`
                    stroke: ${(props) => props.theme.neutral0};
                    fill: ${(props) => props.theme.neutral0};
                `;
        }
    }};
`;

const ButtonText = styled.span<{ isSelected: boolean; iconColor: string }>`
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    width: 100%;
    min-width: 50px;
    padding-left: ${checkBoxSize + 8}px;
    pointer-events: none;
    display: block;
    z-index: 1;
    position: relative;

    ${({ iconColor }) => {
        switch (iconColor.toLowerCase()) {
            case "pass":
                return css`
                    color: ${(props: any) => (props.isSelected ? props.theme.neutral0 : props.theme.green500)};
                `;
            case "fail":
                return css`
                    color: ${(props: any) => (props.isSelected ? props.theme.neutral0 : props.theme.red500)};
                `;
            case "flag":
                return css`
                    color: ${(props: any) => (props.isSelected ? props.theme.neutral0 : props.theme.yellow500)};
                `;
            default:
                return css`
                    color: ${(props) => props.theme.neutral0};
                `;
        }
    }};

    &::before {
        content: " ";
        display: inline-block;
        width: ${checkBoxSize}px;
        height: ${checkBoxSize}px;
        position: absolute;
        ${checkedIconPositionCSS};
        box-shadow: inset 0 0 0 2px ${(props) => (props.isSelected ? "none" : props.theme.neutral200)};
        border-radius: 100%;

        .no-touch ${StyledMultiChoiceButton}:hover & {
            box-shadow: inset 0 0 0 2px ${(props) => (props.isSelected ? "transparent" : props.theme.neutral800)};
        }
    }
`;

const Wrapper = styled.div`
    width: 96px;

    ${StyledMultiChoiceButton} {
        display: block;
    }

    ${StyledMultiChoiceButton}:last-child, ${StyledMultiChoiceButton}:last-child::before {
        border-radius: ${(props) => props.theme.radius1};
    }

    ${StyledMultiChoiceButton}:only-child,
    ${StyledMultiChoiceButton}:only-child::before {
        border-radius: ${(props) => props.theme.radius1};
    }
`;
