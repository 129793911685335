import React from "react";

import { format } from "date-fns";
import styled, { css } from "styled-components";

import { AssessButton, RunCheckButton, ViewButton } from "components/buttons";
import { LoadingIndicator } from "components/loading-indicator";
import { Theme } from "config/theme";
import { ICustomerData } from "types";

export interface Props {
    className?: string;
    customerData: ICustomerData[];
    isLoading?: boolean;
    selectedValue?: any;
    onRunCheck: (ID: string) => void;
    onAssess: (ID: string) => void;
}

export const SummaryTable: React.FC<Props> = ({
    className,
    customerData,
    isLoading,
    selectedValue,
    onRunCheck,
    onAssess,
}) => {
    const isPending = (displayStatus: string | null | undefined, decision: string | null | undefined) =>
        displayStatus === "Ready" && decision === null;
    return (
        <TableWrapper data-testid="summary-table" className={className}>
            <OverlayContainer>
                {isLoading ? (
                    <Overlay>
                        <StyledLoadingIndicator />
                    </Overlay>
                ) : null}
                <TableContainer>
                    <tbody>
                        <TableRow>
                            <TableHeading>
                                <span>Date</span>
                            </TableHeading>
                            <TableHeading>
                                <span>Name</span>
                            </TableHeading>
                            <TableHeading>
                                <span>Source Status</span>
                            </TableHeading>
                            <TableHeading>
                                <span>Decision</span>
                            </TableHeading>
                            <TableHeading>
                                <span>Actions</span>
                            </TableHeading>
                        </TableRow>
                        {customerData.map((data) => {
                            const isSelected = data.ID === selectedValue;
                            return (
                                <TableRowWithData key={data.ID} isSelected={isSelected}>
                                    <StyledDate isSelected={isSelected}>
                                        <span>
                                            {data.dateSubmitted && format(new Date(data.dateSubmitted), "dd/MM/yyyy")}
                                        </span>
                                    </StyledDate>
                                    <td>
                                        <span>
                                            {data.firstName} {data.lastName}
                                        </span>
                                    </td>
                                    <td>
                                        <span data-testid={`summary-table-display-status-${data.ID}`}>
                                            {data.displayStatus}
                                        </span>
                                    </td>
                                    <td>
                                        <DecisionStyle
                                            data-testid={`summary-table-decision-${data.ID}`}
                                            isSelected={isSelected}
                                            decision={
                                                isPending(data.displayStatus, data.decision) ? "Pending" : data.decision
                                            }
                                        >
                                            {isPending(data.displayStatus, data.decision) ? "Pending" : data.decision}
                                        </DecisionStyle>
                                    </td>
                                    <td>
                                        {data.displayStatus === "Submitted" ? (
                                            <RunCheckButton
                                                data-testid={`run-check-button-${data.ID}`}
                                                onClick={() => onRunCheck(data.ID)}
                                            >
                                                RunCheck
                                            </RunCheckButton>
                                        ) : isPending(data.displayStatus, data.decision) ? (
                                            <AssessButton
                                                data-testid={`assess-button-${data.ID}`}
                                                onClick={() => onAssess(data.ID)}
                                            >
                                                Assess
                                            </AssessButton>
                                        ) : data.displayStatus === "Ready" ? (
                                            <ViewButton
                                                data-testid={`view-button-${data.ID}`}
                                                onClick={() => onAssess(data.ID)}
                                            >
                                                View
                                            </ViewButton>
                                        ) : null}
                                    </td>
                                </TableRowWithData>
                            );
                        })}
                    </tbody>
                </TableContainer>
            </OverlayContainer>
        </TableWrapper>
    );
};

const TABLE_WIDTH = 1120;

const TableWrapper = styled.div``;

const OverlayContainer = styled.div`
    position: relative;
    max-width: ${TABLE_WIDTH}px;
`;

const Overlay = styled.div`
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${Theme.grey300};
    opacity: 0.9;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
    position: absolute;
    top: 48%;
    left: 48%;
`;

const TableRow = styled.tr`
    height: 50px;
`;

const TableRowWithData = styled(TableRow)<{ isSelected?: boolean }>`
    color: ${(props) => (props.isSelected ? Theme.neutral0 : Theme.neutral600)};
    background-color: ${(props) => (props.isSelected ? Theme.neutral700 : Theme.neutral0)};
`;

const TableContainer = styled.table`
    font-size: 16px;
    max-width: ${TABLE_WIDTH}px;

    th,
    td {
        padding: 16px 48px 16px 16px;
        text-align: left;
        border-bottom: 1px ${Theme.grey400} solid;
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }
`;

const TableHeading = styled.th`
    color: ${Theme.grey500};
    font-weight: 700;
`;

const StyledDate = styled.td<{ isSelected?: boolean }>`
    color: ${(props) => (props.isSelected ? Theme.neutral0 : Theme.primary500)};
    font-weight: 700;
`;

const DecisionStyle = styled.span<{ decision: string | null; isSelected?: boolean }>`
    font-weight: 700;
    position: relative;
    text-transform: capitalize;
    left: 16px;

    &:before {
        content: "• ";
        font-size: 32px;
        position: absolute;
        left: -16px;
        top: -7px;
    }

    ${({ decision, isSelected }) => {
        switch (decision && decision.toLowerCase()) {
            case "pass":
                return css`
                    color: ${isSelected ? Theme.neutral0 : Theme.primary500};
                `;
            case "pending":
                return css`
                    color: ${isSelected ? Theme.neutral0 : Theme.neutral600};
                `;
            case "fail":
                return css`
                    color: ${isSelected ? Theme.neutral0 : Theme.failure};
                `;
            case "flag":
                return css`
                    color: ${isSelected ? Theme.neutral0 : Theme.action500};
                `;
            default:
                return css`
                    color: ${isSelected ? Theme.neutral0 : Theme.neutral600};
                    left: 0;
                     &:before {
                        content: "";
                `;
        }
    }};
`;
