import React from "react";

import { Theme } from "config/theme";

export interface Props {
    className?: string;
    iconColor: string;
    tickColor?: string;
}

export const IconChecked: React.FC<Props> = ({ className, iconColor, tickColor = Theme.neutral0 }) => {
    return (
        <div className={className}>
            <svg width="16" height="16" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
                    fill={`${iconColor}`}
                    stroke={`${iconColor}`}
                    strokeWidth="2"
                />
                <path
                    d="M19 8L11.8 18L7 13"
                    stroke={tickColor}
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
