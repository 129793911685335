import React, { ChangeEvent } from "react";

import styled from "styled-components";
import { Body } from "typography";

export interface Choice {
    displayName: string;
    name: string;
    value: any;
    extraInfo?: any;
    keyHint?: string;
}

export interface Props {
    className?: string;
    isSelected?: boolean;
    children: React.ReactNode;
    radioButtonName: string;
    radioButtonValue: any;
    onChange: (event: ChangeEvent) => void;
}

export class MultiChoiceButton extends React.Component<Props> {
    render() {
        const { className, isSelected, children, onChange, radioButtonName, radioButtonValue } = this.props;

        return (
            <StyledButton className={className} isSelected={isSelected}>
                {children}
                <HiddenRadioButton
                    isSelected={isSelected}
                    name={radioButtonName}
                    value={radioButtonValue}
                    type="radio"
                    onChange={onChange}
                    checked={isSelected ? true : false}
                />
            </StyledButton>
        );
    }
}

const StyledButton = styled.label<{ isSelected?: boolean }>`
    ${Body};
    display: inline-flex;
    flex: 0 0 auto;
    cursor: ${(props) => (props.isSelected ? "default" : "pointer")};
    border: 1px solid ${(props) => (props.isSelected ? props.theme.success : props.theme.grey400)};
    background: ${(props) => (props.isSelected ? props.theme.success : "#fff")};
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 0;
    padding: 0;
    user-select: none;
    white-space: nowrap;
    text-decoration: none;
    outline: none;
    overflow: hidden;

    .no-touch &:hover {
        color: #fff;
    }
    ::before,
    ::after {
        box-sizing: content-box;
        position: absolute;
        content: "";
        z-index: 1;
    }
    ${(props) =>
        props.isSelected &&
        `
        z-index: 1;
        color: #fff;
        background-color: ${props.theme.success};
        .no-touch &:hover,
        :focus {
            border-color: ${props.theme.success};
            color: #fff;
            ::before,
            ::after {
                border-color: ${props.theme.success};
            }
        }
    `}
`;

const HiddenRadioButton = styled.input<{ isSelected?: boolean }>`
    cursor: ${(props) => (props.isSelected ? "default" : "pointer")};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    z-index: 2;
`;
