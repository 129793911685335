import styled from "styled-components";
import { Body } from "typography";

const BaseButton = styled.button`
    ${Body};
    line-height: 1;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    min-width: 120px;
    font-weight: 600;
`;

export const Button = styled.button`
    ${Body};
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    min-width: 120px;
    color: #fff;
    background: ${(props) => props.theme.success};
`;

export const AssessButton = styled(BaseButton)`
    color: ${(props) => props.theme.neutral0};
    background: ${(props) => props.theme.primary500};
`;

export const RunCheckButton = styled(BaseButton)`
    color: ${(props) => props.theme.action800};
    background: ${(props) => props.theme.action75};
`;

export const ViewButton = styled(BaseButton)`
    color: ${(props) => props.theme.primary500};
    background: ${(props) => props.theme.primary50};
`;

export const PlaceholderButton = styled(BaseButton)`
    color: ${(props) => props.theme.neutral800};
    background: ${(props) => props.theme.neutral0};
`;
