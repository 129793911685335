import React from "react";

import { decimalToPercentage, penceToPounds, withThousandsSeparator } from "@wayhome-uk/utils";
import { format } from "date-fns";

import { IAddressData } from "types";

import {
    DataContainer,
    DataHeading,
    DataInfo,
    Divider,
    Heading,
    ICreditSummaryProps,
    Paragraph,
} from "../credit-summary";

const AddressDisplay: React.FC<IAddressData> = (address) => (
    <>
        <Paragraph>
            {address.buildingNumber} {address.thoroughfare}
        </Paragraph>
        <Paragraph>
            {address.subBuilding && `${address.subBuilding},`} {address.buildingName}
        </Paragraph>
        <Paragraph>{address.postcode}</Paragraph>
        <Paragraph>{address.town}</Paragraph>
        <Paragraph>{address.country}</Paragraph>
    </>
);

export const SummarySection: React.FC<ICreditSummaryProps> = ({ customerData }) => (
    <>
        <Heading>Summary</Heading>
        <DataContainer>
            <DataHeading>Customer Name</DataHeading>
            <DataInfo>
                {customerData.firstName} {customerData.lastName}
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Address</DataHeading>
            <DataInfo>{customerData.addresses && <AddressDisplay {...customerData.addresses[0]} />}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Retrieval date</DataHeading>
            <DataInfo>
                {customerData.dateRetrieved && format(new Date(customerData.dateRetrieved), "dd MMMM yyyy")}
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>CreditScore</DataHeading>
            <DataInfo>{customerData.creditScore}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Credit rating</DataHeading>
            <DataInfo>{customerData.creditRating}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Mortgage amount</DataHeading>
            <DataInfo>
                {customerData.mortgageAmount &&
                    `£${withThousandsSeparator(penceToPounds(customerData.mortgageAmount))}`}
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Total unsecured debt</DataHeading>
            <DataInfo>
                {customerData.totalUnsecuredDebt &&
                    `£${withThousandsSeparator(penceToPounds(customerData.totalUnsecuredDebt))}`}
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Total credit limit</DataHeading>
            <DataInfo>
                {customerData.totalCreditLimit &&
                    `£${withThousandsSeparator(penceToPounds(customerData.totalCreditLimit))}`}
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Total credit limit used</DataHeading>
            <DataInfo>
                {customerData.totalCreditLimitUsed &&
                    customerData.totalCreditLimit &&
                    decimalToPercentage(customerData.totalCreditLimitUsed / customerData.totalCreditLimit)}
                %
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>
                Total default payments in the past <strong>60 months</strong>
            </DataHeading>
            <DataInfo>{customerData.totalDefaultPaymentsSixtyMonths}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>
                Total default payments in the past <strong>12 months</strong>
            </DataHeading>
            <DataInfo>{customerData.totalDefaultPaymentsTwelveMonths}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>
                Total missed payments in the past <strong>60 months</strong>
            </DataHeading>
            <DataInfo>{customerData.totalMissedPaymentsSixtyMonths}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>
                Total missed payments in the past <strong>12 months</strong>
            </DataHeading>
            <DataInfo>{customerData.totalMissedPaymentsTwelveMonths}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Number of CCJs</DataHeading>
            <DataInfo>{customerData.publicInfoRecordsCount}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Amount of CCJs</DataHeading>
            <DataInfo>
                {customerData.publicInfoRecordsValue &&
                    `£${withThousandsSeparator(penceToPounds(customerData.publicInfoRecordsValue))}`}
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Months since most recent public info record started</DataHeading>
            <DataInfo>{customerData.monthsSinceMostRecentPublicInfoRecordStarted}</DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataHeading>Outstanding bankruptcy or IVA detected</DataHeading>
            <DataInfo>
                {!customerData.outstandingBankruptcyOrIVADetected
                    ? "No"
                    : customerData.outstandingBankruptcyOrIVADetected}
            </DataInfo>
            <Divider />
        </DataContainer>
    </>
);
