import React, { useEffect } from "react";

import { Button, Header3, LoadingIndicator } from "@wayhome-uk/design-system";
import { useAuth } from "react-oidc-context";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { GoogleIcon } from "components/icons/google-icon";

export const LoginPage: React.FC = () => {
    const searchParams = new URLSearchParams(window.location.search);

    const history = useHistory();
    const { isAuthenticated, signinRedirect, isLoading } = useAuth();

    const handleLogin = () => {
        signinRedirect();
    };

    useEffect(() => {
        const redirectValue = searchParams.get("redirect");
        if (isAuthenticated) {
            const redirectUrl: string = redirectValue || localStorage.getItem("redirectParam") || "/";
            localStorage.removeItem("redirectParam");
            history.push(redirectUrl);
        } else {
            if (redirectValue) {
                localStorage.setItem("redirectParam", redirectValue);
            }
        }
    }, [isLoading, isAuthenticated, history, searchParams]);

    if (isLoading || isAuthenticated) {
        return <StyledLoadingIndicator>Authenticating, please wait</StyledLoadingIndicator>;
    }

    return (
        <StyledPageWrapper>
            <StyledContainer>
                <Header3 marginBottom={{ sm: 32 }}>Credit Check Tool</Header3>

                <ButtonWithIcon onClick={handleLogin}>
                    <GoogleIcon /> Sign in with Google
                </ButtonWithIcon>
            </StyledContainer>
        </StyledPageWrapper>
    );
};

const StyledPageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #f9f8f8;
    height: 100%;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
    padding-block: 64px;
`;

const ButtonWithIcon = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 12px auto 0 auto;
`;

const StyledContainer = styled.div`
    display: grid;
    justify-content: center;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(8, 38, 43, 0.3);
    width: 500px;
    padding: 48px;
`;
