import React from "react";

import { format } from "date-fns";

import { DataContainer, DataInfo, Divider, Heading, ICreditSummaryProps } from "../credit-summary";

export const HistorySection: React.FC<ICreditSummaryProps> = ({ customerData }) => (
    <>
        <Heading>History</Heading>
        <DataContainer>
            <DataInfo>
                Assessed on {customerData.timestamp && format(new Date(customerData.timestamp), "dd MMMM yyyy")}
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataInfo>
                Ready on {customerData.dateCreated && format(new Date(customerData.dateCreated), "dd MMMM yyyy")}
            </DataInfo>
            <Divider />
        </DataContainer>
        <DataContainer>
            <DataInfo>
                Requested on{" "}
                {customerData.dateSubmitted && format(new Date(customerData.dateSubmitted), "dd MMMM yyyy")}
            </DataInfo>
        </DataContainer>
    </>
);
