import React from "react";

import { format } from "date-fns";

import { DataContainer, DataHeading, DataInfo, Divider, Heading, ICreditSummaryProps } from "../credit-summary";
import { decisionHierarchyData } from "./data";

const formatValue = (value?: string | number | boolean, formatType?: string) => {
    if (typeof value === "string" && formatType === "date") {
        return format(new Date(value), "dd MMMM yyyy");
    }
    return value;
};

export const DecisionHierarchySection: React.FC<ICreditSummaryProps> = ({ customerData }) => (
    <>
        <Heading>Decision Hierarchy</Heading>
        {decisionHierarchyData.map((value) => (
            <DataContainer key={value.fieldName}>
                <DataHeading>{value.displayName}</DataHeading>
                <DataInfo>{formatValue(customerData.decisionHierarchy?.[value.fieldName], value.formatType)}</DataInfo>
                <Divider />
            </DataContainer>
        ))}
    </>
);
