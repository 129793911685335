import React from "react";

import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "routes";

function App() {
    const getOidcConfig = (): AuthProviderProps => {
        if (typeof window !== "undefined") {
            return {
                authority: process.env.REACT_APP_OIDC_AUTHORITY || "",
                client_id: process.env.REACT_APP_OIDC_CLIENT_ID || "",
                redirect_uri: `${window.location.origin}/login`,
                onSigninCallback: () => {
                    const currentURL = new URL(window.location.href);

                    const unusedQueryParams = ["state", "code", "session_state", "realm"];
                    unusedQueryParams.forEach((unusedQuery) => {
                        currentURL.searchParams.delete(unusedQuery);
                    });

                    window.history.replaceState({}, document.title, currentURL.href);
                },
                userStore: new WebStorageStateStore({ store: window.localStorage }),
            };
        }

        return {};
    };

    return (
        <AuthProvider {...getOidcConfig()}>
            <Router>
                <Routes />
            </Router>
        </AuthProvider>
    );
}

export default App;
