import React, { useEffect, useReducer, useState } from "react";

import { useSystemNotification } from "@wayhome-uk/components";
import { Button, LoadingIndicator } from "@wayhome-uk/design-system";
import { dataToObjectByKey } from "@wayhome-uk/utils";
import { useAuth } from "react-oidc-context";
import { Redirect, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import { CreditContainer } from "components/credit-container";
import { CreditSummary } from "components/credit-summary";
import { SummaryTable } from "components/summary-table";
import { Theme } from "config/theme";
import { IAction, ICustomerState } from "types";
import { customFetch } from "utils/helpers";

interface IProps {
    className?: string;
}

export const reducer = (state: ICustomerState, action: IAction) => {
    const { payload, type } = action;
    switch (type) {
        case "UPDATE_ALL":
            return {
                ...dataToObjectByKey(action.payload, "ID"),
            };
        case "UPDATE_SINGLE":
            return {
                ...state,
                [payload.ID]: { ...state[payload.ID], ...payload },
            };
        default:
            return state;
    }
};

export const CreditCheckReview: React.FC<IProps> = ({ className }) => {
    const { submittedBy } = useParams<{ submittedBy: string }>();
    const location = useLocation();
    const [isSearching, setIsSearching] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCreditID, setSelectedCreditID] = useState<string>("");
    const [creditRerunning, setCreditRerunning] = useState(false);
    const [customerData, customerDataDispatcher] = useReducer(reducer, []);
    const { isAuthenticated } = useAuth();
    const selectedCreditCheck = customerData[selectedCreditID];
    const { addAutoDismissNotification } = useSystemNotification();
    const applicantId = Object.values(customerData)[0]?.applicantID;

    useEffect(() => {
        document.title = "Credit Check Review";
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (!isAuthenticated && !submittedBy) return;
                const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/v0/credit-searches?submittedBy=${submittedBy}`;

                const { ok, body } = await customFetch(API_ENDPOINT, "GET");

                if (ok) {
                    customerDataDispatcher({ type: "UPDATE_ALL", payload: body.results });
                }
            } finally {
                setIsSearching(false);
            }
        })();
    }, [submittedBy, isAuthenticated]);

    const handleRunCheck = async (ID: string) => {
        try {
            setIsLoading(true);
            const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/v0/credit-searches/${ID}/process`;
            const { ok, body } = await customFetch(API_ENDPOINT, "POST", {});

            if (ok) {
                setIsLoading(false);
                customerDataDispatcher({ type: "UPDATE_SINGLE", payload: body });
            } else {
                setIsLoading(false);
                customerDataDispatcher({ type: "UPDATE_SINGLE", payload: { ID: ID, displayStatus: "Error" } });
            }
        } catch (error) {
            setIsLoading(false);
            customerDataDispatcher({ type: "UPDATE_SINGLE", payload: { ID: ID, displayStatus: "Error" } });
        }
    };

    const handleSubmit = async (ID: string, values: { decision: string; comments: string }) => {
        setSelectedCreditID("");

        const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/v0/credit-searches/${ID}`;

        const { ok, body } = await customFetch(API_ENDPOINT, "PATCH", values);

        if (ok) {
            customerDataDispatcher({
                type: "UPDATE_SINGLE",
                payload: { ID: ID, decision: values.decision, comments: values.comments, timestamp: body.timestamp },
            });
        }
    };

    const handleRerun = async () => {
        if (!applicantId) {
            return addAutoDismissNotification({
                id: `credit-rerun-${Date.now()}`,
                type: "error",
                message: "Cannot rerun credit when applicantID is null",
            });
        }

        const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/v0/credit-rerun/${applicantId}`;

        try {
            setCreditRerunning(true);

            const { ok, status } = await customFetch(API_ENDPOINT, "POST");

            setCreditRerunning(false);

            if (ok) {
                addAutoDismissNotification({
                    id: `credit-rerun-${Date.now()}`,
                    type: "success",
                    message: "Credit successfully re-run!",
                });

                return;
            }

            let notificationMessage = "";
            switch (status) {
                case 400:
                    notificationMessage = "Bad request";
                    break;
                case 404:
                    notificationMessage = "Application ID not found";
                    break;
                case 422:
                    notificationMessage = "Unprocessable credit state";
                    break;
                case 500:
                    notificationMessage = "Internal server error";
                    break;
                default:
                    notificationMessage = `Something went wrong: ${status}`;
            }

            addAutoDismissNotification({
                id: `credit-rerun-${Date.now()}`,
                type: "error",
                message: notificationMessage,
            });
        } catch (error) {
            setCreditRerunning(false);
            addAutoDismissNotification({
                id: `credit-rerun-${Date.now()}`,
                type: "error",
                message: "Something went wrong",
            });
        }
    };

    const handleAssess = (ID: string) => {
        setSelectedCreditID(ID);
    };

    const handleCancel = () => {
        setSelectedCreditID("");
    };

    if (!isAuthenticated) {
        return <Redirect to={`/login?redirect=${location.pathname}`} />;
    }

    if (isSearching) {
        return <LoadingIndicator>Please wait...</LoadingIndicator>;
    }

    return (
        <Wrapper className={className}>
            <RerunButton type="button" disabled={creditRerunning} onClick={handleRerun}>
                Rerun credit
            </RerunButton>
            <SummaryTable
                customerData={Object.values(customerData)}
                isLoading={isLoading}
                selectedValue={selectedCreditID}
                onRunCheck={handleRunCheck}
                onAssess={handleAssess}
            />
            {selectedCreditCheck && (
                <CreditWrapper key={selectedCreditCheck.ID}>
                    <CreditContainer
                        ID={selectedCreditCheck.ID}
                        date={selectedCreditCheck.dateRetrieved ? selectedCreditCheck.dateRetrieved : ""}
                        decision={selectedCreditCheck.decision ? selectedCreditCheck.decision : ""}
                        comments={selectedCreditCheck.comments ? selectedCreditCheck.comments : ""}
                        onCancel={handleCancel}
                        onSubmit={handleSubmit}
                    />
                    <CreditSummary customerData={customerData[selectedCreditID]} />
                </CreditWrapper>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
`;

const CreditWrapper = styled.div`
    margin-left: 24px;
`;

const StyledButton = styled(Button)`
    padding: 8px 16px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    border-radius: 4px;
`;

const RerunButton = styled(StyledButton)`
    grid-column-start: 1;
    grid-column-end: -1;
    max-width: fit-content;
    background-color: ${Theme.action500};
    color: ${Theme.action900};
    margin-bottom: 16px;

    :disabled {
        background-color: ${Theme.grey500};
        color: ${Theme.grey700};
    }
`;
